import {React, useState} from "react";
import disney from "../images/disney.png";
import hulu from "../images/hulu.png";
import netflix from "../images/netflix.png";
import max from "../images/max.png";
import paramount from "../images/paramount.png";
import peacock from "../images/peacock.png";
import prime from "../images/prime.png";
import apple from "../images/apple.png";

export default function Services({servicesList, changeServices}){

    //these states will be used to check what services have been selected
    const [huluSelected, changeHulu] = useState(false)
    const [disneySelected, changeDisney] = useState(false)
    const [maxSelected, changeMax] = useState(false)
    const [netflixSelected, changeNetflix] = useState(false)
    const [paramountSelected, changeParamount] = useState(false)
    const [peacockSelected, changePeacock] = useState(false)
    const [primeSelected, changePrime] = useState(false)
    const [appleSelected, changeApple] = useState(false)

    const remove = (itemRemoved) => {
        // Use filter to create a new array excluding the item to be removed
        const updatedList = servicesList.filter(item => item !== itemRemoved);
        changeServices(updatedList);
      };

    function select(serviceSelected){
        //checking what service has been selected
        switch(serviceSelected){
            case"hulu":
                //have an if else statement that changes true if currently false and vice versa
                if(huluSelected === false){
                    changeHulu(true)
                    changeServices([...servicesList,'hulu.subscription'])
                }
                else if(huluSelected === true){
                    changeHulu(false)
                    remove('hulu.subscription')
                }
                break;
            case"disney":
                if(disneySelected === false){
                    changeDisney(true)
                    changeServices([...servicesList,'disney'])
                }
                else if(disneySelected === true){
                    changeDisney(false)
                    remove('disney')
                }
                break;
            case"max":
                if(maxSelected === false){
                    changeMax(true)
                    changeServices([...servicesList,'hbo'])
                }
                else if(maxSelected === true){
                    changeMax(false)
                    remove('hbo')
                }
                break;
            case"netflix":
                if(netflixSelected === false){
                    changeNetflix(true)
                    changeServices([...servicesList,'netflix'])
                }
                else if(netflixSelected === true){
                    changeNetflix(false)
                    remove('netflix')
                }
                break;
            case"paramount":
                if(paramountSelected === false){
                    changeParamount(true)
                    changeServices([...servicesList,'paramount'])
                }
                else if(paramountSelected === true){
                    changeParamount(false)
                    remove('paramount')
                }
                break;
            case"peacock":
                if(peacockSelected === false){
                    changePeacock(true)
                    changeServices([...servicesList,'peacock.subscription'])
                }
                else if(peacockSelected === true){
                    changePeacock(false)
                    remove('peacock.subscription')
                }
                break;
            case"prime":
                if(primeSelected === false){
                    changePrime(true)
                    changeServices([...servicesList,'prime.subscription'])
                }
                else if(primeSelected === true){
                    changePrime(false)
                    remove('prime.subscription')
                }
                break;
            case"apple":
                if(appleSelected === false){
                    changeApple(true)
                    changeServices([...servicesList,'apple.subscription'])
                }
                else if(appleSelected === true){
                    changeApple(false)
                    remove('apple.subscription')
                }
                break;
            default:
                break;
        }
    }

    return(
    <>
      <h1 className="services-title">CHOOSE YOUR STREAMING SERVICES</h1>
      <div className="services-container">
            <button className={huluSelected ? "service icon-container pressed" : "service icon-container"} onClick={() => select("hulu")}>
                <img src={hulu} className="icon" alt="streaming-choice-hulu"/>
                <p className="icon-title">Hulu</p>
            </button>
            <button className={netflixSelected ? "service icon-container pressed" : "service icon-container"} onClick={() => select("netflix")}>
                <img src={netflix} className="icon" alt="streaming-choice-hulu"/>
                <p className="icon-title">Netflix</p>
            </button>
            <button className={maxSelected ? "service icon-container pressed" : "service icon-container"} onClick={() => select("max")}>
                <img src={max} className="icon" alt="streaming-choice-hulu"/>
                <p className="icon-title">Max</p>
            </button>
            <button className={disneySelected ? "service icon-container pressed" : "service icon-container"} onClick={() => select("disney")}>
                <img src={disney} className="icon" alt="streaming-choice-hulu"/>
                <p className="icon-title">Disney+</p>
            </button>
            <button className={paramountSelected ? "service icon-container pressed" : "service icon-container"} onClick={() => select("paramount")}>
                <img src={paramount} className="icon" alt="streaming-choice-hulu"/>
                <p className="icon-title">Paramount</p>
            </button>
            <button className={peacockSelected ? "service icon-container pressed" : "service icon-container"} onClick={() => select("peacock")}>
                <img src={peacock} className="icon" alt="streaming-choice-hulu"/>
                <p className="icon-title">Peacock</p>
            </button>
            <button className={primeSelected ? "service icon-container pressed" : "service icon-container"} onClick={() => select("prime")}>
                <img src={prime} className="icon" style={{scale: '1.25'}} alt="streaming-choice-hulu"/>
                <p className="icon-title">Prime</p>
            </button>
            <button className={appleSelected ? "service icon-container pressed" : "service icon-container"} onClick={() => select("apple")}>
                <img src={apple} className="icon" alt="streaming-choice-apple"/>
                <p className="icon-title">Apple TV</p>
            </button>
      </div>
    </>
    )
}