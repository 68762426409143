import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

export default function Result({userInput, stringServices}) {
  // eslint-disable-next-line
  const [movie, setMovie] = useState({
    services: stringServices,
    country: "us",
    title: userInput,
  });
  const [respond, changeResponded] = useState(false)
  const [movies, changeMovies] = useState("")

      /*
netflix
prime.subscription
hulu.subscription
apple.subscription
hbo
peacock.free OR peacock.subscription (idk how peacock works)
paramount
disney
*/

  const hasEffectRun = useRef(false);

  useEffect(() => {
    if (!hasEffectRun.current){
      axios
        .post('https://pickflick-server.vercel.app/', movie)
        //.post('http://localhost:8082/', movie)
        .then((res) => {
          console.log(res.data);
          changeMovies(res.data);
          changeResponded(true)
        })
        .catch((err) => {
          console.log('Error from axios');
          console.log(err);
        });
      hasEffectRun.current = true;
    }
  }, [movie]);

  return (
    <>
    {(respond === true) ?
    (<>
      <h1 className="services-title">MOVIES RELATED TO {movie.title.toUpperCase()}...</h1>
      {(movies && movies.length === 0) ? (
        <div className="load"></div>
      ) : (
        <div className="results-container">
          {movies && movies.map((item, index) => {
            const uniqueServices = new Set();
    
            return (
              <div key={index} className="results-child">
                <h1 className="services-title">Movie Title:</h1>
                {item.title ? (
                  <>
                    <h1 className="movie-title">{item.title}</h1>
                    {item.overview === "" ? (
                      <h1 className="services-title">No Description Available</h1>
                    ) : (
                      <>
                        <h1 className="services-title">Description:</h1>
                        <p className="desc">{item.overview}</p>
                      </>
                    )}
                    <h1 className="services-title">Streaming Services:</h1>
                    {item.streamingInfo && item.streamingInfo.us &&
                                      item.streamingInfo.us.map((innerItem, innerIndex) => {
                                        if (!uniqueServices.has(innerItem.service)) {
                                          uniqueServices.add(innerItem.service);
                                          return <div key={innerIndex} className="desc">{innerItem.service}</div>;
                                        }
                          
                                        return null;
                                      })
                                    }
                                    <h1>
                  <a
                    href={`https://www.google.com/search?q=${item.title}`}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    Learn More?
                  </a>
                </h1>
                  </>
                ) : 
                <>
                  <h1 className="movie-title">{item}</h1>
                  <h1 className="services-title">No Description Available</h1>
                  <h1 className="services-title">No Streaming Services Available</h1>
                  <a
                    href={`https://www.google.com/search?q=${item}`}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                    
                  >Learn More?</a>
                </>}
              </div>
            );
          })}
        </div>
      )}
    </>
    ):(
        <h1 className="services-title load-text">Loading Results...</h1>
      )}
  </>
  );
  
  
}
