import { useState } from "react";
import React from "react";

export default function Question({renderResults, movie, setMovie, servicesList, changeStringServices}){

    const [animationClass, setAnimationClass] = useState('typing-before');
    const [textBox, renderTextbox] = useState(false)

    const handleAnimationEnd = () => {
        setAnimationClass('typing-after');
        renderTextbox(true)
    };

    const handleInputChange = (event) => {
        setMovie(event.target.value);
      };

    const convertToString = () => {
    // Use join to concatenate array elements with a comma separator
    const stringList = servicesList.join(', ');
    console.log(stringList); // Log the result to the console (you can use it as needed)
    changeStringServices(stringList)
    };

    function submit(){
        if(movie !== ""){
            renderResults(true)
            convertToString()
        }
    }

    return(
        <>
            <div className="typing-container">
                <h1 className={`services-title typing ${animationClass}`} onAnimationEnd={handleAnimationEnd}>I AM LOOKING FOR A MOVIE SIMILAR TO:</h1>
                {textBox && 
                    <input className="text-box" placeholder="Enter Movie/Show Title" value={movie} onChange={handleInputChange}></input>
                }
            </div>
            {textBox &&
            <div className="submit">
                <button onClick={submit} className="submit-button">SUBMIT</button>
            </div>
            }
        </>
    )
}