import React, {useState} from 'react';
import Heading from './components/heading';
import Services from './components/services';
import Question from './components/question';
import Result from './components/result';

function App() {

  const [showResults, setShowResults] = useState(false)
  const [movie, setMovie] = useState("")
  const [servicesList, changeServices] = useState([])
  const [stringServices, changeStringServices] = useState("")

  const renderResults = () => {
    setShowResults(true);
  };

  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
      <link href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet"></link>
      
      <Heading/>
      <Services servicesList={servicesList} changeServices={changeServices}/>
      <Question renderResults={renderResults} movie={movie} setMovie={setMovie} servicesList={servicesList} changeStringServices={changeStringServices}/>
      {showResults &&
        <Result userInput={movie} stringServices={stringServices}/>
      }
    </>
  );
}

export default App;
